import { computed } from '@vue/composition-api';
import {
  ACTIVITY_STATUSES,
  ALARM_STATUSES,
  BATTERY_STATUSES,
  GPS_STATUSES
} from '@/modules/objects/compositions/objectStatus';
import {
  DEFAULT_FILTER_TYPES,
  defaultFilters
} from '@/compositions/filter.helpers';

export const OBJECT_LIST_FILTER = 'OBJECT_LIST_FILTER';

const NO_FILTER_ITEM_VALUE = '__NO__';
const NO_FILTER_ITEM = {
  name: 'No',
  value: NO_FILTER_ITEM_VALUE
};

export function useObjectFilters({ objectTypes, objectGroups, geotags }) {
  const types = computed(() =>
    objectTypes?.value.map(({ name, id }) => ({ name, value: id }))
  );
  const groups = computed(() =>
    objectGroups?.value.map(({ name, id }) => ({ name, value: id }))
  );
  const geotagList = computed(() =>
    geotags?.value.map(({ name, id }) => ({ name, value: id }))
  );

  const FILTER_TYPES = {
    FAVOURITES: 'FAVOURITES',
    MUTED: 'MUTED',
    STATUS: 'STATUS',
    RESPONSE_STATUS: 'RESPONSE_STATUS',
    BATTERY: 'BATTERY',
    ALARM: 'ALARM',
    GPS: 'GPS',
    TYPE: 'TYPE',
    COLORS: 'COLORS',
    GROUPS: 'GROUPS',
    GEOTAGS: 'GEOTAGS'
  };

  return [
    defaultFilters[DEFAULT_FILTER_TYPES.FAVOURITES],
    defaultFilters[DEFAULT_FILTER_TYPES.MUTED],
    defaultFilters[DEFAULT_FILTER_TYPES.STATUS],
    {
      id: FILTER_TYPES.RESPONSE_STATUS,
      default: null,
      component: {
        name: 'select',
        label: 'Response status',
        items: [
          {
            label: 'All',
            value: null
          },
          {
            label: 'Active',
            value: ACTIVITY_STATUSES.ACTIVE
          },
          {
            label: 'No response',
            value: ACTIVITY_STATUSES.NO_RESPONSE
          },
          {
            label: 'Unknown',
            value: ACTIVITY_STATUSES.UNKNOWN
          }
        ]
      },
      filterFunc: (item, value) => {
        console.log(item)
        return item.statusesActivity === value || value === null
      }
    },
    {
      id: FILTER_TYPES.BATTERY,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Battery',
        items: [
          {
            name: 'Full',
            value: BATTERY_STATUSES.FULL
          },
          {
            name: 'Low',
            value: BATTERY_STATUSES.LOW
          },
          {
            name: 'DC',
            value: BATTERY_STATUSES.DC
          },
          {
            name: 'Unknown',
            value: BATTERY_STATUSES.UNKNOWN
          }
        ]
      },
      filterFunc: (item, valueArray) =>
        valueArray?.map(v => v.value).includes(item.statusesBatteryType) ||
        !valueArray?.length
    },
    {
      id: FILTER_TYPES.ALARM,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Alarm',
        items: [
          {
            name: 'Off',
            value: ALARM_STATUSES.OFF
          },
          {
            name: 'On',
            value: ALARM_STATUSES.ON
          },
          {
            name: 'Triggered',
            value: ALARM_STATUSES.TRIGGERED
          }
        ]
      },
      filterFunc: (item, valueArray) =>
        valueArray?.map(v => v.value).includes(item.statusesAlarm) ||
        !valueArray.length
    },
    {
      id: FILTER_TYPES.GPS,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'GPS',
        items: [
          {
            name: 'Off',
            value: GPS_STATUSES.OFF
          },
          {
            name: 'Fixed',
            value: GPS_STATUSES.FIXED
          },
          {
            name: 'Not fixed',
            value: GPS_STATUSES.NOT_FIXED
          }
        ]
      },
      filterFunc: (item, valueArray) =>
        valueArray?.map(v => v.value).includes(item.statusesGps) ||
        !valueArray.length
    },
    {
      id: FILTER_TYPES.TYPE,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Type',
        items: types.value
      },
      filterFunc: (item, valueArray) =>
        valueArray?.map(v => v.value).includes(item.schemaId) ||
        !valueArray.length
    },
    defaultFilters[DEFAULT_FILTER_TYPES.COLORS],
    {
      id: FILTER_TYPES.GROUPS,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Groups',
        items: [NO_FILTER_ITEM, ...groups.value]
      },
      filterFunc: (item, valueArray) => {
        const values = valueArray?.map(v => v.value);
        if (
          values?.includes(NO_FILTER_ITEM_VALUE) &&
          !item.objectGroupIds?.length
        ) {
          return true;
        }
        return (
          values?.some(groupId => item.objectGroupIds.includes(groupId)) ||
          !values.length
        );
      }
    },
    {
      id: FILTER_TYPES.GEOTAGS,
      default: [],
      component: {
        name: 'tagSelect',
        label: 'Geotags',
        items: [NO_FILTER_ITEM, ...geotagList.value]
      },
      filterFunc: (item, valueArray) => {
        const values = valueArray?.map(v => v.value);
        if (
          values?.includes(NO_FILTER_ITEM_VALUE) &&
          !item.positionGeotagId &&
          !item.geotagIds?.length
        ) {
          return true;
        }
        return (
          values?.some(geotagId =>
            [item.positionGeotagId, ...item.geotagIds].includes(geotagId)
          ) || !valueArray.length
        );
      }
    }
  ];
}
